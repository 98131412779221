$(function() {
	console.log('main.js in DEV');

    FastClick.attach(document.body);

    var script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC45NZhpFkugQ7COHSmObMr8HKvSz1Vb54&callback=initialize";
    document.body.appendChild(script);


    $(".menu-item").hover(function () {
        $(this).toggleClass("active");
    });

    $(".dropdown").hover(function () {
        $(this).toggleClass("open");
    });

    $('.mobile-nav').slicknav();

});

function initialize() 	{
    var map;
    var bounds = new google.maps.LatLngBounds();
    var mapOptions = {
        mapTypeId: 'roadmap'
    };

    // Display a map on the page
    map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
    map.setTilt(45);

    var iconBase = 'https://maps.google.com/mapfiles/kml/shapes/';
        var icons = {
          default: {
            icon: iconBase + 'default.png'
         }
    };


    // Multiple Markers
    var markers = [
        ['Bram Egberts', 52.391206,6.5347156],
        ['Jolanda Jacobi ', 52.2228905,6.5797202],
        ['Arjan Meutstege ', 52.1997039,6.5404792],
        ['Henk Ramerman ', 52.4666899,6.433868],
        ['Wim Slots ', 52.4723961,6.4410976],
        ['Erik Jansman ', 52.3962431,6.3719583],
        ['Jan Brönninkreef ', 52.2953041,6.351479],
        ['Gerrit Veneklaas ', 52.2818672,6.3492084],
        ['Henk Bronsvoort ', 52.2775699,6.3569988],
        ['Alexander Maassen van den Brink ', 52.3369716,6.5619828],
        ['Stefan Woolderink ', 52.3545679,6.5613927],
        ['Gerrit Kippers ', 52.3475513,6.5510647],
        ['Erik Rensen', 52.2926866,6.3528146],
        ['Gerrit Klaasses ', 52.2917303,6.3521488],
        ['Riny Paalman ', 52.292272,6.362788],
        ['Jan Meijer ', 52.4004015,6.5603027],
        ['Bert Lammersen ', 52.3840622,6.57544],
        ['Herman Olthof ', 52.4028014,6.5720052],
        ['Arjan Waalderink ', 52.3463882,6.5766746],
        ['Edwin Jansen ', 52.3399256,6.5293366],
        ['Henk Vinkers ', 52.3492101,6.6049466],
        ['Henrian Heuver ', 52.3710309,6.551785],
        ['Joan ter Weele ', 52.2799151,6.5389438],
        ['Jan Henk Hargeerds-Trip ', 52.2229698,6.5244124],
        ['Harald Dubbink ', 52.4389424,6.4258712],
        ['Erik Immink ', 52.4574569,6.4259686]

    ];

    // Info Window Content
    var infoWindowContent = [
        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/08/egberts-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Bram Egberts</h3><br/><a class="link" href="/bram-egberts#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/jacobi-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Jolanda Jacobi</h3><br/><a class="link" href="/jolanda-jacobi#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/meutstege-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Arjan Meutstege</h3><br/><a class="link" href="/arjan-meutstege#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/ramerman-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Henk Ramerman</h3><br/><a class="link" href="/henk-ramerman#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/slots-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Wim Slots</h3><br/><a class="link" href="/wim-slots#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/jansman-1-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Erik Jansman</h3><br/><a class="link" href="/erik-jansman#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/bronninkreef-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Jan Brönninkreef</h3><br/><a class="link" href="/jan-bronninkreef#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/veneklaas-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Gerrit Veneklaas</h3><br/><a class="link" href="/gerrit-veneklaas#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/bronsvoort-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Henk Bronsvoort</h3><br/><a class="link" href="/henk-bronsvoort#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/maassen-vdbrink-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Alexander Maassen van den Brink</h3><br/><a class="link" href="/alexander-maassen-van-den-brink#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/woolderink-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Stefan Woolderink</h3><br/><a class="link" href="/stefan-woolderink#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/kippers-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Gerrit Kippers</h3><br/><a class="link" href="/gerrit-kippers#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/rensen-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Erik Rensen</h3><br/><a class="link" href="/erik-rensen#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/klaasses-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Gerrit Klaasses</h3><br/><a class="link" href="/gerrit-klaasses#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/paalman-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Riny Paalman</h3><br/><a class="link" href="/riny-paalman#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/meijer-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Jan Meijer</h3><br/><a class="link" href="/jan-meijer#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/lammersen-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Bert Lammersen</h3><br/><a class="link" href="/bert-lammersen#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/olthof-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Herman Olthof</h3><br/><a class="link" href="/herman-olthof#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/waalderink-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Arjan Waalderink</h3><br/><a class="link" href="/arjan-waalderink#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/jansen-notter-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Edwin Jansen</h3><br/><a class="link" href="/edwin-jansen#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/vinkers-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Henk Vinkers</h3><br/><a class="link" href="/henk-vinkers#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/heuver-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Henrian Heuver</h3><br/><a class="link" href="/henrian-heuver#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/terweele-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Joan ter Weele</h3><br/><a class="link" href="/joan-ter-weele#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/harregeerds-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Jan Henk Hargeerds-Trip</h3><br/><a class="link" href="/jan-henk-hargeerds-trip#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/dubbink-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Harald Dubbink</h3><br/><a class="link" href="/harald-dubbink#scroll">Meer info</a>' +
        '<div>'],

        ['<div class="info_content">' +
        '<div class="left">' +
        '<p class="gm_text"><img src="/wp-content/uploads/2017/09/immink-125x125.jpg"></p>'+
        '</div>' +
        '<div class="right">' +
        '<h3>Erik Immink</h3><br/><a class="link" href="/erik-immink#scroll">Meer info</a>' +
        '<div>']
    ];

    // var offline = 'http://localhost:8888/boerenvoordrinkwater/wp-content/themes/bvdw/library/images/marker.png';
    var online = '/wp-content/themes/bvdw/library/images/marker.png'
    // Display multiple markers on a map
    var infoWindow = new google.maps.InfoWindow(), marker, i;
    var image = {
          url: online,
          // This marker is 20 pixels wide by 32 pixels high.
          size: new google.maps.Size(33, 46),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(0, 33)
        };
    // Loop through our array of markers & place each one on the map
    for( i = 0; i < markers.length; i++ ) {
        var position = new google.maps.LatLng(markers[i][1], markers[i][2]);

        bounds.extend(position);
        marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: image,
            title: markers[i][0]
        });

        // Allow each marker to have an info window
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infoWindow.setContent(infoWindowContent[i][0]);
                infoWindow.open(map, marker);
            }
        })(marker, i));

        // Automatically center the map fitting all markers on the screen
        map.fitBounds(bounds);
    }

    // Override our map zoom level once our fitBounds function runs (Make sure it only runs once)
    var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function(event) {
        this.setZoom(10);
        google.maps.event.removeListener(boundsListener);
    });

	}
